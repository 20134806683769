<template>
    <div>
        <div v-if="loadingFinished">

            <v-card flat>
                <v-card-text>

                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-row>
                                <v-col cols="12" class="my-0 py-0">
                                    <v-card flat>
                                        <v-card-title class="my-0 py-1" style="background: var(--v-greyBase-base);"><v-icon
                                                class="mr-2" style="color: grey">insights</v-icon>
                                            Document Performance Analysis</v-card-title>
                                        <v-progress-linear v-if="loadingCustomerMetrics" color="primary"
                                            indeterminate></v-progress-linear>
                                        <v-card-text class="my-0 py-0">

                                            <v-list dense class="my-0 py-0">

                                                <v-card flat v-for="(item, index) in customerDocumentMetrics.customer"
                                                    :key="item.locationDocument.id" class="my-0 py-0">
                                                    <v-divider v-if="index > 0"></v-divider>
                                                    <v-card-title class="mt-1 py-1" style=" font-size: 16px">
                                                        <v-avatar size="36"
                                                            v-if="item.locationDocument.regulatoryBody && item.locationDocument.regulatoryBody.logo"
                                                            style="background :white" class="mr-2">
                                                            <v-img :src="item.locationDocument.regulatoryBody.logo"></v-img>
                                                        </v-avatar>
                                                        <span style="font-size: 14px"
                                                            v-if="item.locationDocument.regulatoryBody" class="mr-2">
                                                            {{ item.locationDocument.regulatoryBody.name }}: </span>
                                                        <span style="font-size: 14px">
                                                            {{ item.locationDocument.name }}</span>
                                                    </v-card-title>
                                                    <v-card-text class="my-0 pt-0">
                                                        <v-row>
                                                            <v-col cols="3" class="text-center my-0 py-0">
                                                                <!-- <span style="font-size: 12px; font-weight: bold"></span> -->
                                                            </v-col>
                                                            <v-col cols="3" class="text-center my-0 py-0">
                                                                <span style="font-size: 12px; font-weight: bold">ETD >
                                                                    Submitted</span>
                                                            </v-col>
                                                            <v-col cols="3" class="text-center my-0 py-0">
                                                                <span style="font-size: 12px; font-weight: bold">ETD >
                                                                    Complete</span>
                                                            </v-col>
                                                            <v-col cols="3" class="text-center my-0 py-0">
                                                                <span style="font-size: 12px; font-weight: bold">Submit >
                                                                    Complete</span>
                                                            </v-col>
                                                            <!-- Customer -->
                                                            <v-col cols="12" sm="3" class="my-0 py-0 mx-0 px-0">
                                                                <v-list dense class="my-0 py-0 mx-0 px-0">
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title class="text-wrap text-left">
                                                                                Customer
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title class="wrap-text text-left">
                                                                                Global
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-col>
                                                            <v-col cols="12" sm="3" class="text-center my-0 py-0">
                                                                <v-list dense class="my-0 py-0">
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{
                                                                                    parseFloat(item.avgETDtoComplete).toFixed(2)
                                                                                }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{ parseFloat(getGlobalTotal(item,
                                                                                    'avgETDtoComplete')).toFixed(2) }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                <span
                                                                                    :style="{ color: percentageDifference(item, 'avgETDtoComplete') > 0 ? 'orange' : 'var(--v-success-base)' }"
                                                                                    class="ml-1">({{
                                                                                        percentageDifference(item,
                                                                                            'avgETDtoComplete') }} %)</span>
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-col>
                                                            <v-col cols="12" sm="3" class="text-center my-0 py-0">
                                                                <v-list dense class="my-0 py-0">
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{
                                                                                    parseFloat(item.avgSubmittedToComplete).toFixed(2)
                                                                                }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{ parseFloat(getGlobalTotal(item,
                                                                                    'avgSubmittedToComplete')).toFixed(2) }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                <span
                                                                                    :style="{ color: percentageDifference(item, 'avgSubmittedToComplete') > 0 ? 'orange' : 'var(--v-success-base)' }"
                                                                                    class="ml-1">({{
                                                                                        percentageDifference(item,
                                                                                            'avgSubmittedToComplete') }} %)</span>
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-col>

                                                            <v-col cols="12" sm="3" class="text-center my-0 py-0 ">
                                                                <v-list dense class="my-0 py-0">
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{
                                                                                    parseFloat(item.avgETDToSubmitted).toFixed(2)
                                                                                }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{ parseFloat(getGlobalTotal(item,
                                                                                    'avgETDToSubmitted')).toFixed(2) }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <v-list-item style="height: 15px">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                <span
                                                                                    :style="{ color: percentageDifference(item, 'avgETDToSubmitted') > 0 ? 'orange' : 'var(--v-success-base)' }"
                                                                                    class="ml-1">({{
                                                                                        percentageDifference(item,
                                                                                            'avgETDToSubmitted') }} %)</span>
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                    <v-card style="max-height: 45vh" flat class="my-0 py-0">
                                        <v-card-title class="my-0  py-1" style="background: var(--v-greyBase-base);"><v-icon
                                                class="mr-2" style="color: grey">insights</v-icon>
                                            Container Volume Analysis (2022/2023) <v-spacer></v-spacer> <v-btn icon
                                                @click="viewContainerVolume()"><v-icon>launch</v-icon></v-btn>
                                        </v-card-title>
                                        <v-card-text style="min-height: 20vh">
                                            <StackedLineChart v-if="!loadingForecast" :data="forecast.preSeason"
                                                :dataset2="forecast.forecast" :dataset2Color="'orange'"
                                                :dataset3="forecast.actual" :dataset3Color="'green'"
                                                :labels="forecast.labels" :key="forecastKey" defaultChart="line"
                                                xAxisLabel="Sailing Week" :color="'#3297a8'" :textColor="'grey'" :legend="forecast.legend"/>
                                        </v-card-text>
                                    </v-card>
                                    <v-card flat>
                                        <v-card-title class="my-0  py-1" style="background: var(--v-greyBase-base);"><v-icon
                                                class="mr-2" style="color: grey">cancel</v-icon>
                                            Service Failures ({{ serviceFailures.totalFiles }} Files)</v-card-title>
                                        <v-progress-linear v-if="serviceFailureLoading" color="primary"
                                            indeterminate></v-progress-linear>
                                        <v-card-text>

                                            <v-list dense>
                                                <v-list-item v-for="(failure, index) in categoryFailure" :key="index">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ failure.name }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle v-for="org in failure.data" :key="org.id">
                                                            <v-chip small class="mr-2">{{ org.count }} ({{ percentage(org.count, serviceFailures.totalFiles) }}%)</v-chip>
                                                            
                                                            {{ org.name }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item v-if="categoryFailure && categoryFailure.length == 0">
                                                    <v-list-item-content class="text-center">
                                                        <span style="color: grey">No failures found</span>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                    <v-card flat>
                                        <v-card-title class="my-0  py-1" style="background: var(--v-greyBase-base);"><v-icon
                                                class="mr-2" style="color: grey">category</v-icon>
                                            Commodity Analysis</v-card-title>
                                        <v-progress-linear v-if="loadingCommodity" color="primary"
                                            indeterminate></v-progress-linear>
                                        <v-card-text>

                                            <v-list dense>
                                                <v-list-item v-for="product in commodity" :key="product.productId">
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ product.product.name }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle v-if="product.product.parentProduct">
                                                            {{ product.product.parentProduct.name }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-chip color="indigo" small class="ma-1">Containers:
                                                            {{ product.containers }}

                                                        </v-chip>
                                                        <v-chip color="blue-grey" small class="ma-1">Pallets:
                                                            {{ product.pallets }}
                                                        </v-chip>
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <v-list-item v-if="commodity && commodity.length == 0">
                                                    <v-list-item-content class="text-center">
                                                        <span style="color: grey">No products found</span>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>




                                </v-col>


                            </v-row>



                        </v-col>
                        <v-col cols="12" sm="6" class="mb-0 pb-0">
                            <v-row>
                                <v-col cols="12" sm="6" class="my-0 py-0">
                                    <v-card flat class="my-0 py-0">
                                        <v-card-title class="my-0 py-1" style="background: var(--v-greyBase-base);"><v-icon
                                                class="mr-2" style="color: grey">location_on</v-icon>
                                            Destination Analysis</v-card-title>
                                        <v-card-text class="ma-0 pa-0">
                                            <v-progress-linear v-if="loadingLocation" color="primary"
                                                indeterminate></v-progress-linear>
                                            <v-tabs grow v-model="locationTab" show-arrows class="mx-0 px-0">
                                                <v-tab href="#country">
                                                    Country
                                                </v-tab>
                                                <v-tab href="#region">
                                                    Region
                                                </v-tab>
                                                <!-- <v-tab href="#map">
                                                    Map
                                                </v-tab> -->

                                                <!-- <v-tab href="#destination">
                                                Destination
                                            </v-tab> -->
                                                <v-tabs-items v-model="locationTab">
                                                    <v-tab-item value="region">
                                                        <v-card flat class="my-0 py-0">
                                                            <v-card-text class="my-0 py-0">
                                                                <v-list dense class="my-0 py-0">
                                                                    <v-list-item v-for="(region, index) in location.region"
                                                                        :key="index">
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{ region.targetRegion }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                        <v-list-item-action>
                                                                            <v-chip small>
                                                                                {{ region.count }}
                                                                            </v-chip>
                                                                        </v-list-item-action>
                                                                    </v-list-item>
                                                                    <v-list-item v-if="location.region.length == 0">
                                                                        <v-list-item-content class="text-center">
                                                                            <span style="color: grey">No countries
                                                                                found</span>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-tab-item>
                                                    <v-tab-item value="country">
                                                        <v-card flat class="my-0 py-0">
                                                            <v-card-text class="my-0 py-0">
                                                                <v-list dense style="max-height: 30vh; overflow-y: auto"
                                                                    class="my-0 py-0">
                                                                    <v-list-item
                                                                        v-for="(country, index) in location.country"
                                                                        :key="index">
                                                                        <v-list-item-avatar>
                                                                            <v-avatar>
                                                                                <v-img contain v-if="country.country.code"
                                                                                    :src="`https://cdn.loglive.io/flags/4x3/${country.country.code.toLowerCase()}.svg`"></v-img>
                                                                            </v-avatar>
                                                                        </v-list-item-avatar>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{ country.country.country }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                        <v-list-item-action>
                                                                            <v-chip small>
                                                                                {{ country.count }}
                                                                            </v-chip>
                                                                        </v-list-item-action>
                                                                    </v-list-item>
                                                                    <v-list-item
                                                                        v-if="location.country && location.country.length == 0">
                                                                        <v-list-item-content class="text-center">
                                                                            <span style="color: grey">No countries
                                                                                found</span>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-tab-item>
                                                    <!-- <v-tab-item value="map">
                                                      
                                                    </v-tab-item> -->
                                                </v-tabs-items>
                                            </v-tabs>
                                        </v-card-text>
                                    </v-card>

                                </v-col>

                                <v-col cols="12" sm="6" class="my-0 py-0">
                                    <v-card style="height: 42vh" flat class="my-0 py-0">
                                        <v-card-title class="my-0  py-1" style="background: var(--v-greyBase-base);"><v-icon
                                                class="mr-2" style="color: grey">directions_boat</v-icon>
                                            Shipment Overview</v-card-title>
                                        <v-card-text style="min-height: 20vh">
                                            <SmoothLineChart v-if="!loadingOverallMetrics" :data="containerMetricData"
                                                :dataset2="shipmentMetricData" :dataset2Color="'orange'"
                                                :labels="containerMetricLabels" :key="overallMetricKey" defaultChart="bar"
                                                xAxisLabel="Sailing Week" :color="'#3297a8'" :textColor="'grey'" />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="my-0 py-0">
                                    <v-card style="max-height: 45vh" flat class="my-0 py-0">

                                        <v-card-title class="my-0  py-1" style="background: var(--v-greyBase-base);"><v-icon
                                                class="mr-2" style="color: grey">insights</v-icon>
                                            Current VS Previous Volumes</v-card-title>
                                        <v-card-text class="my-0 py-0" style="min-height: 20vh">
                                            <DynamicSmoothLineChart v-if="!loadingForecast" :data="previousVolume.data"
                                                :labels="previousVolume.labels" :key="forecastKey" defaultChart="line"
                                                xAxisLabel="Sailing Week" :textColor="'grey'" />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="my-0 py-0">
                                    <v-card>
                                        <v-card-title class="my-0  py-1" style="background: var(--v-greyBase-base);"><v-icon
                                                class="mr-2" style="color: grey">location_on</v-icon>
                                            Shipment Destinations</v-card-title>
                                        <v-card-text class="ma-0 pa-0 pb-1" style=" border-radius: 20px">
                                            <gmap-map :zoom.sync="zoom" class="ma-0 pa-0" :key="mapKey" :options="{
                                                styles: mapStyle,
                                                disableDefaultUI: true,
                                            }" :center.sync="mapCenter" ref="mapRef"
                                                style="height: 500px; width: 100%;">
                                                <gmap-custom-marker :offsetY="-18"
                                                    v-for="(location, index) in location.country" :key="index"
                                                    alignment="center" :marker="{
                                                        lng: location.country.long,
                                                        lat: location.country.lat,
                                                    }">
                                                    <div @click="selectedMarker = location; markerKey++">
                                                        <div
                                                            style="border-radius: 50%; background-color: var(--v-primary-base) !important;border:  3px solid var(--v-primary-base)">
                                                            <v-avatar size="32">
                                                                <v-img contain v-if="location.country.code"
                                                                    :src="`https://cdn.loglive.io/flags/4x3/${location.country.code.toLowerCase()}.svg`"></v-img>
                                                            </v-avatar>
                                                        </div>

                                                        <div class="map-marker"></div>
                                                    </div>

                                                </gmap-custom-marker>
                                                <gmap-info-window v-if="selectedMarker" :key="markerKey" class="pa-0 ma-0"
                                                    :options="{
                                                        maxWidth: 300,
                                                    }" :opened="selectedMarker != null" :position="{
    lng: selectedMarker.country.long,
    lat: selectedMarker.country.lat,
}">
                                                    <v-card light flat
                                                        style="background: white !important; color: white !important; background-color: white !important; border-radius: 20px;"
                                                        class="grey--text pa-0 ma-0" v-if="selectedMarker">
                                                        <v-card-text class="grey--text pa-0 ma-0">
                                                            <v-list dense class="pa-0 ma-0">
                                                                <v-list-item style="color: white" class="pa-0 ma-0">
                                                                    <v-list-item-avatar>
                                                                        <v-avatar size="32">
                                                                            <v-img contain
                                                                                v-if="selectedMarker.country.code"
                                                                                :src="`https://cdn.loglive.io/flags/4x3/${selectedMarker.country.code.toLowerCase()}.svg`"></v-img>
                                                                        </v-avatar>
                                                                    </v-list-item-avatar>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title style="color: black">
                                                                            {{
                                                                                selectedMarker.country.country
                                                                            }}
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item class="pa-0 ma-0">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            Containers
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                    <v-list-item-action>
                                                                        <v-chip>
                                                                            {{ selectedMarker.count }}
                                                                        </v-chip>
                                                                    </v-list-item-action>

                                                                </v-list-item>
                                                            </v-list>
                                                        </v-card-text>
                                                    </v-card>
                                                </gmap-info-window>
                                            </gmap-map>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>

            <v-dialog v-model="containerVolumeDialog" persistent width="1400px" :fullscreen="$vuetify.breakpoint.mobile">
                <v-card>
                    <v-toolbar flat color="transparent">
                        <v-toolbar-title>
                            <v-icon class="mr-2" style="color: grey">insights</v-icon>
                            Container Volume Detail
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn text @click="containerVolumeDialog = false">X</v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table dense :headers="containerVolumeHeaders" :items="containerVolumeDetail"
                            :loading="containerVolumeDetailLoading"></v-data-table>
                        <DynamicSmoothLineChart v-if="!containerVolumeDetailLoading" :data="containerVolumeDetailData"
                            :labels="containerVolumeDetailLabels" defaultChart="line" xAxisLabel="Region"
                            :textColor="'grey'" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <div v-else> <v-card flat style="
                                      height: 70vh;
                                      padding-top: 0;
                                      margin-top: 0;
                                      background: transparent;
                                    " class="text-center">
                <v-card-text>
                    <valhalla-loading />
                </v-card-text>
            </v-card>
        </div>

    </div>
</template>
<script>
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import DynamicSmoothLineChart from '../Charts/DynamicSmoothLineChart.vue'

import SmoothLineChart from '../Charts/SmoothLineChart.vue'
import StackedLineChart from '../Charts/StackedLineChart.vue'

export default {
    props: ['selectedCustomer'],
    components: {
        DynamicSmoothLineChart,
        GmapCustomMarker,
        SmoothLineChart,
        StackedLineChart
    },
    data: () => ({
        commodity: [],
        commodityKey: 3000,
        customerDocumentMetrics: {
            all: [],
            customer: []
        },
        customerMetricKey: 2000,
        containerVolumeDialog: false,
        containerVolumeDetail: [],
        containerVolumeDetailLoading: false,
        containerVolumeHeaders: [
            {
                text: 'Region',
                value: 'Region'
            },
            {
                text: 'Actual',
                value: 'actualValue'
            },
            {
                text: 'Forecast',
                value: 'forecastValue'
            },
            {
                text: 'Pre-Season',
                value: 'preseasonValue'
            }
        ],
        forecast: {
            preSeason: [],
            forecast: [],
            actual: [],
            labels: [],
            legend:['Pre-season','Forecast','Actual']
        },
        forecastKey: 6000,
        forecastTab: 0,
        loadingCommodity: false,
        loadingCustomerMetrics: false,
        location: {
            country: [],
            region: [],
            destination: []
        },
        locationTab: 0,
        loadingForecast: false,
        loadingLocation: false,
        locationKey: 4000,
        loadingOverallMetrics: false,
        overallMetrics: {},
        overallMetricKey: 1000,
        previousVolume: {
            data: [],
            labels: [],
            legend: []
        },
        // Map Settings
        mapCenter: {
            lng: 0,
            lat: 0,
        },
        loaded: false,
        pod: null,
        pol: null,
        mapKey: 0,
        markerKey: 5000,
        mapStyle: [
            {
                elementType: "geometry",
                stylers: [
                    {
                        color: "#f5f5f5",
                    },
                ],
            },
            {
                elementType: "labels.icon",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161",
                    },
                ],
            },
            {
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#f5f5f5",
                    },
                ],
            },
            {
                featureType: "administrative",
                elementType: "geometry",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#bdbdbd",
                    },
                ],
            },
            {
                featureType: "poi",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#eeeeee",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575",
                    },
                ],
            },
            {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#e5e5e5",
                    },
                ],
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#ffffff",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "road.arterial",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#757575",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#c1d6e0",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#616161",
                    },
                ],
            },
            {
                featureType: "road.local",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#9e9e9e",
                    },
                ],
            },
            {
                featureType: "transit",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "transit.line",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#e5e5e5",
                    },
                ],
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#eeeeee",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#9cb0ba",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#ffffff",
                    },
                ],
            },
        ],
        selectedMarker: null,
        serviceFailures: {
            totalFiles: 0,
            data: []
        },
        serviceFailureLoading: false,
        zoom: 1.5,
        zoomKey: 0,
    }),
    computed: {
        categoryFailure(){
            let result = []
            let categories = [...new Set(this.serviceFailures.data.map(x => x.serviceFailureCategoryId))]
            for(let i=0;i<categories.length;i++){
                let filter = this.serviceFailures.data.filter(x => x.serviceFailureCategoryId == categories[i])
                let uniqueOrganisations = [...new Set(filter.map(x => x.responsibleOrganisationId))]
                let obj = {
                    name: filter[0].name,
                    data: []
                }
                for(let j=0;j<uniqueOrganisations.length;j++){
                    let findOrganisationData = filter.filter(x => x.responsibleOrganisationId == uniqueOrganisations[j])
                    let orgObj = {
                        id: j+1000*i,
                        name: uniqueOrganisations[j] && findOrganisationData[0].responsibleOrganisation? findOrganisationData[0].responsibleOrganisation.name:findOrganisationData[0].responsibleParty,
                        count: findOrganisationData.reduce((a, b)=> a + b.count, 0)
                    }
                    obj.data.push(orgObj)
                }
                result.push(obj)
            }
            return result
        },
        containerMetricData() {
            return this.overallMetrics && this.overallMetrics.containerSummary ? this.overallMetrics.containerSummary.map(x => x.containers) : []
        },
        containerMetricLabels() {
            return this.overallMetrics && this.overallMetrics.containerSummary ? this.overallMetrics.containerSummary.map(x => x.sailingWeek) : []
        },
        containerVolumeDetailData() {
            let actual = {
                data: this.containerVolumeDetail.map(x => x.actualValue),
                type: 'bar',
                color: '#4CAF50',
                symbol: 'circle',
                smooth: true,
                showInLegend: true,
                name: 'Actual'
            }
            let forecast = {
                data: this.containerVolumeDetail.map(x => x.forecastValue),
                type: 'bar',
                color: '#0091EA',
                symbol: 'circle',
                smooth: true,
                showInLegend: true,
                name: 'Forecast'
            }
            let preseason = {
                data: this.containerVolumeDetail.map(x => x.preseasonValue),
                type: 'bar',
                color: '#FF9800',
                symbol: 'circle',
                smooth: true,
                showInLegend: true,
                name: 'Preseason'
            }
            return [actual, forecast, preseason]
        },
        containerVolumeDetailLabels() {
            console.log(this.containerVolumeDetail.map(x => x.Region))
            return this.containerVolumeDetail.map(x => x.Region)
        },
        google: VueGoogleMaps.gmapApi,
        loadingFinished() {
            return !this.loadingOverallMetrics && !this.loadingLocation && !this.loadingForecast && !this.loadingCommodity && !this.loadingCustomerMetrics && !this.serviceFailureLoading
        },
        shipmentMetricData() {
            return this.overallMetrics && this.overallMetrics.shipmentSummary ? this.overallMetrics.shipmentSummary.map(x => x.count) : []
        },
        shipmentMetricLabels() {
            return this.overallMetrics && this.overallMetrics.shipmentSummary ? this.overallMetrics.shipmentSummary.map(x => x.sailingWeek) : []
        }
    },
    watch: {
        'selectedCustomer.id': {
            immediate: true,
            handler() {
                this.getLatestOverallMetrics()
                this.getCustomerPerformance()
                this.getCustomerCommodityOverview()
                this.getCustomerLocationOverview()
                this.getCustomerServiceFailures()
                // if (this.selectedCustomer.accountReference) {
                //     this.getCustomerForecast()
                // }
            }
        }
    },
    created() {
        // this.getLatestOverallMetrics()
        // this.getCustomerPerformance()
        // this.getCustomerCommodityOverview()
        // this.getCustomerLocationOverview()
        // console.log(this.selectedCustomer)

    },
    methods: {
        async getCustomerPerformance() {
            this.loadingCustomerMetrics = true
            this.customerDocumentMetrics = await this.$API.getCustomerPerformance({
                customerRelationshipId: this.selectedCustomer.id
            })
            this.loadingCustomerMetrics = false
            this.customerMetricKey++
        },
        getGlobalTotal(item, field) {
            let find = this.customerDocumentMetrics.all.find(x => x.locationDocument.id == item.locationDocument.id)
            return find ? find[field] : null
        },
        async getCustomerCommodityOverview() {
            this.loadingCommodity = true
            this.commodity = await this.$API.getCustomerCommodityOverview({
                customerRelationshipId: this.selectedCustomer.id
            })
            this.loadingCommodity = false
            this.commodityKey++
        },
        async getCustomerServiceFailures(){
            this.serviceFailureLoading = true
            this.serviceFailures = await this.$API.getCustomerServiceFailures(this.selectedCustomer.id)
            this.serviceFailureLoading = false
        },
        async getCustomerForecast() {
            this.loadingForecast = true
            let forecastData = await this.$API.getCustomerForecast({
                params: {
                    clientProfile: this.selectedCustomer.accountReference,
                    season: '2022/23'
                }
            })
            let previousData = await this.$API.getCustomerForecast({
                params: {
                    clientProfile: this.selectedCustomer.accountReference,
                    type: 'ACTUAL'
                }
            })
            let uniqueWeeks = [...new Set(forecastData.map(x => x.Week))]
            for (let i = 0; i < uniqueWeeks.length; i++) {
                let week = uniqueWeeks[i]
                let weekData = forecastData.filter(x => x.Week == week)
                let findPreSeason = weekData.find(x => x.Type == 'PRE-SEASON') ? weekData.find(x => x.Type == 'PRE-SEASON').Volume : null
                let findForecast = weekData.find(x => x.Type == 'FORECAST') ? weekData.find(x => x.Type == 'FORECAST').Volume : null
                let findActual = weekData.find(x => x.Type == 'ACTUAL') ? weekData.find(x => x.Type == 'ACTUAL').Volume : null
                if ((findPreSeason && findPreSeason != 0) || (findForecast && findForecast != 0) || (findActual && findActual != 0)) {
                    this.forecast.preSeason.push(findPreSeason)
                    this.forecast.forecast.push(findForecast)
                    this.forecast.actual.push(findActual)
                    this.forecast.labels.push(week.substring(4, 6))
                }
            }
            let uniquePreviousYears = [...new Set(previousData.map(x => x.sailingWeekYear))]
            uniquePreviousYears.sort((a, b) => b - a)
            if (uniquePreviousYears.length > 4) {
                uniquePreviousYears = uniquePreviousYears.slice(0, 4)
            }
            this.previousVolume.labels = [...new Set(previousData.map(x => x.sailingWeek))]
            this.previousVolume.legend = uniquePreviousYears
            let colors = ['#2962FF', 'teal', 'orange', 'pink', 'purple', 'red', 'yellow', 'blue', 'grey']
            for (let i = 0; i < uniquePreviousYears.length; i++) {
                let year = uniquePreviousYears[i]
                let weekData = previousData.filter(x => x.sailingWeekYear == year)
                let dataset = []
                for (let j = 0; j < this.previousVolume.labels.length; j++) {
                    let find = weekData.find(x => x.sailingWeek == this.previousVolume.labels[j])
                    if (find) {
                        dataset.push(find.Volume)
                    } else {
                        dataset.push(null)
                    }
                }
                let obj = {
                    data: dataset,
                    type: 'line',
                    color: colors[i] ?? 'grey',
                    symbol: 'circle',
                    smooth: true,
                    showInLegend: true,
                    name: uniquePreviousYears[i]
                }
                this.previousVolume.data.push(obj)
            }
            this.loadingForecast = false
            this.forecastKey++
        },
        async getForecastDetail() {
            this.containerVolumeDetailLoading = true
            this.containerVolumeDetail = await this.$API.getForecastDetail({
                params: {
                    clientProfile: this.selectedCustomer.accountReference,
                    season: '2022/23'
                }
            })
            this.containerVolumeDetailLoading = false
        },
        async getCustomerLocationOverview() {
            this.loadingLocation = true
            this.location = await this.$API.getCustomerLocationOverview({
                customerRelationshipId: this.selectedCustomer.id
            })
            //     this.$gmapApiPromiseLazy().then(() => {
            //     this.loadShipPolygon();
            //   })
            this.loadingLocation = false
            this.locationKey++
        },

        async getLatestOverallMetrics() {
            this.loadingOverallMetrics = true
            this.overallMetrics = await this.$API.getLatestContainerMetrics({
                customerRelationshipId: this.selectedCustomer.id
            })
            this.loadingOverallMetrics = false
            this.overallMetricKey++
        },
        percentage(item, total){
            return (parseFloat(item) / parseFloat(total) * 100).toFixed(2)
        },
        percentageDifference(item, field) {
            let all = this.customerDocumentMetrics.all.find(x => x.locationDocument.id == item.locationDocument.id)
            let customer = this.customerDocumentMetrics.customer.find(x => x.locationDocument.id == item.locationDocument.id)
            let denominator = parseFloat(all[field]) < 0 ? parseFloat(all[field]) * (-1) : parseFloat(all[field])
            return all && customer ? (((parseFloat(customer[field]) - parseFloat(all[field])) / denominator) * 100).toFixed(2) : null
        },
        viewContainerVolume() {
            this.containerVolumeDetail = []
            this.getForecastDetail()
            this.containerVolumeDialog = true
        }
    }

}
</script>
<style scoped>
.map-marker {
    /* position: relative; */
}

.map-marker::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    /* top: -5px; */
    bottom: -20px;
    left: 10px;
    border: 10px solid transparent;
    border-top: 17px solid var(--v-primary-base);
}
</style>
